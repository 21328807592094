
import { mapGetters } from 'vuex'
export default {
    name: 'MaintenanceComponent',
    data: () => ({
        dialog: false,
        maintenance: {},
    }),
    computed: {
        ...mapGetters({
            maintenanceGetter: 'themes/settings/maintenance',
            isWebsiteMaintenance: 'settings/isWebsiteMaintenance',
        }),
        defaultCurrency() {
            return this.$store.state.settings.defaultCurrency
        },
    },
    async mounted() {
        if (this.isInternational) {
            const result = this.isWebsiteMaintenance(this.defaultCurrency)
            if (result && result.status) {
                this.maintenance = result
                this.dialog = true
                if (this.handleCookie('token')) this.logout()
            }
        } else {
            const result = await this.$store.dispatch(
                'themes/settings/fetchMaintenance'
            )
            if (result && result.status) {
                this.dialog = true
                this.maintenance = this.maintenanceGetter
                if (this.handleCookie('token')) this.logout()
            }
        }
    },
}
