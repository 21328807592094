
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'MessageDialog',
    mixins: [cms],
    data() {
        return {
            showCloseIcon: false,
            showIconTimeout: null,
            showLive: false,
        }
    },
    computed: {
        ...mapGetters({
            rightFloatingSupportIcon: 'cms/rightFloatingSupportIcon',
            maintenance: 'themes/settings/maintenance',
        }),
    },
    methods: {
        onHoverMainImg() {
            this.showCloseIcon = true
            if (this.showIconTimeout) {
                clearTimeout(this.showIconTimeout)
                this.showIconTimeout = null
            }
        },
        onHoverMainImageLeave() {
            this.showIconTimeout = setTimeout(() => {
                this.showCloseIcon = false
            }, 400)
        },
        mobileTimeout() {
            this.showCloseIcon = false
        },
        mouseLeave() {
            this.showLive = false
            this.showCloseIcon = false
        },
        onClickFab() {
            this.showLive = !this.showLive
            this.showCloseIcon = !this.showCloseIcon
            const fabOptions = document.getElementById('fab-options')
            fabOptions.setAttribute(
                'style',
                `transform: scale(${this.showLive ? '1' : '0'})`
            )
        },
        openNewWindow(link) {
            if (this.isMobileOnly()) {
                const fabOptions = document.getElementById('fab-options')
                fabOptions.setAttribute('style', 'transform: scale(0)')
                this.showLive = false
                this.showCloseIcon = false
            }
            window.open(
                !link.toLowerCase().startsWith('http')
                    ? 'http://' + link
                    : link,
                'Google',
                'width=550,height=600'
            )
        },
    },
}
