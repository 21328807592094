
import { mapState } from 'vuex'
export default {
    name: 'NavBar',
    data: () => ({
        drawer: true,
        hostname: null,
        gameIndex: null,
    }),
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            player: (state) => state.player.player,
            menubars: (state) => state.cms.menus.menus,
        }),
    },
    watch: {
        '$route.query.idx'(value) {
            this.gameIndex = value
        },
    },
    mounted() {
        this.hostname = window.location.hostname
        if (this.$route?.query?.idx) {
            this.gameIndex = this.$route.query.idx
        }
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    methods: {
        onGameChange(index, title) {
            this.gameIndex = index
            this.$router.push(`/games?idx=${this.gameIndex}&title=${title}`)
        },
        getImageUri(icon) {
            if (!icon) return null
            const menubarIcon = ~~icon
            let imagePath = 'theme-3/mobile/games/v2/'
            // menubar_icon
            // 5 = slot
            // 3 = live casino
            // 8 = Virtual Sport
            // 1 = animal
            // 2 = finishing
            // 4 = Lottery
            // 6 = Sport
            // 7 = Table
            // 9 = Others
            if (menubarIcon === 1) imagePath += 'animal.png'
            else if (menubarIcon === 2) imagePath += 'fishing.png'
            else if (menubarIcon === 3) imagePath += 'casino.png'
            else if (menubarIcon === 4) imagePath += 'lottery.png'
            else if (menubarIcon === 5) imagePath += 'slot.png'
            else if (menubarIcon === 6) imagePath += 'sport.png'
            else if (menubarIcon === 7) imagePath += 'table-games.png'
            else if (menubarIcon === 8) imagePath += 'virtual.png'
            else if (menubarIcon === 9) imagePath += 'other-games.png'

            return imagePath
        },
    },
}
